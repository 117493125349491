
import { Options, Vue } from 'vue-class-component'
import { UISelect, UInput, UTable } from 'unit-uikit'

import Tooltip from '@/components/Tooltips/Tooltips.vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import Loader from '@/components/Loader/Loader.vue'

import { SUBORDER_STATUS, SUBORDER_COLORS, SUBORDER_CHANGE_REASON } from '@/utils/consts'
import { ESuborderStatus } from '@/types/api-values'
import { formatPrice } from '@/utils/helpers'

function formatDate(date: string) {
  if (!date) {
    return ''
  }
  const dateArr = date?.split('-')
  if (dateArr.length === 3) {
    return dateArr.reverse().join('.')
  }
  return date
}

@Options({
  components: {
    UTable,
    UISelect,
    UInput,
    Tooltip,
    ErrorModal,
    Loader
  },
  data() {
    return {
      formatPrice,
      loading: true,
      searchString: '',
      failedEdit: false,
      statusFilter: '',
      tasks: [],
      reason_for_cancellation: '',
      cancelOrderReason: '',
      subOrderId: null,
      order: null,
      reasonComment: '',
      selectedReason: [],
      backToWorkFiles: [],
      backToWorkOptions: SUBORDER_CHANGE_REASON,
      editableTask: null,
      submitTry: false,
      unitTypesList: [],
      ESuborderStatus,
      pageX: 0,
      pageY: 0,
      suborderFilterStatuses: [
        {
          id: 'status_all',
          name: 'Все'
        },
        {
          id: ESuborderStatus.created,
          name: SUBORDER_STATUS[ESuborderStatus.created]
        },
        {
          id: ESuborderStatus.work,
          name: SUBORDER_STATUS[ESuborderStatus.work]
        },
        {
          id: ESuborderStatus.check,
          name: SUBORDER_STATUS[ESuborderStatus.check]
        },
        {
          id: ESuborderStatus.act,
          name: SUBORDER_STATUS[ESuborderStatus.act]
        },
        {
          id: ESuborderStatus.payment,
          name: SUBORDER_STATUS[ESuborderStatus.payment]
        },
        {
          id: ESuborderStatus.finished,
          name: SUBORDER_STATUS[ESuborderStatus.finished]
        },
        {
          id: ESuborderStatus.dispute,
          name: SUBORDER_STATUS[ESuborderStatus.dispute]
        },
        {
          id: ESuborderStatus.cancelled,
          name: SUBORDER_STATUS[ESuborderStatus.cancelled]
        }
      ],
      suborderStatuses: [
        {
          id: ESuborderStatus.created,
          name: SUBORDER_STATUS[ESuborderStatus.created]
        },
        {
          id: ESuborderStatus.work,
          name: SUBORDER_STATUS[ESuborderStatus.work]
        },
        {
          id: ESuborderStatus.check,
          name: SUBORDER_STATUS[ESuborderStatus.check]
        },
        {
          id: ESuborderStatus.act,
          name: SUBORDER_STATUS[ESuborderStatus.act]
        },
        {
          id: ESuborderStatus.payment,
          name: SUBORDER_STATUS[ESuborderStatus.payment]
        },
        {
          id: ESuborderStatus.finished,
          name: SUBORDER_STATUS[ESuborderStatus.finished]
        },
        {
          id: ESuborderStatus.dispute,
          name: SUBORDER_STATUS[ESuborderStatus.dispute]
        },
        {
          id: ESuborderStatus.cancelled,
          name: SUBORDER_STATUS[ESuborderStatus.cancelled]
        }
      ]
    }
  },
  methods: {
    updateSuborderStatus($event: any, task: any) {
      const status = $event.target.value
      if (status && task.id) {
        return this.$store
          .dispatch('order/updateSuborderStatus', {
            id: task.id,
            data: {
              type: status === ESuborderStatus.cancelled ? 'cancel_suborder' : 'change_suborder',
              reason: ['another_reason'],
              new_status: status
            }
          })
          .then(() => {
            this.getTasks()

            if (this.$route.params.id) {
              this.$store
                .dispatch('order/getSingleOrder', this.$route.params.id)
                .then((response: any) => {
                  this.order = response
                })
                .catch((e: any) => {
                  console.error(e)
                })
            }
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
    },
    getUnitType(unitType: any) {
      if (this.unitTypesList) {
        const type = this.unitTypesList.filter((u: any) => {
          return u.id + '' === unitType + ''
        })
        return (type && type[0] && type[0].name) || ''
      }
      return ''
    },
    getStartDate(task: any) {
      return formatDate(task.start_date || task.initial_start_date)
    },
    getFinishDate(task: any) {
      return formatDate(task.finish_date || task.initial_finish_date)
    },
    getPrice(task: any) {
      if (task.price && +task.price > 0) {
        return task.price
      }
      return task.initial_price
    },
    getQuantity(task: any) {
      if (task.quantity && +task.quantity > 0) {
        return task.quantity
      }
      return task.initial_quantity
    },
    closeEditMenu() {
      this.tasks = this.tasks.map((task: any) => {
        task.showEditMenu = false
        return task
      })
    },
    getTasks() {
      this.loading = true

      this.$store
        .dispatch('order/getSuborderByOrder', this.$route.params.id)
        .then((res: any) => {
          this.loading = false

          this.tasks = res.map((sub: any) => {
            if (!sub.name) {
              sub.name = sub.initial_name
            }
            if (!sub.start_date) {
              sub.start_date = formatDate(sub.initial_start_date)
            }
            if (!sub.finish_date) {
              sub.finish_date = formatDate(sub.initial_finish_date)
            }
            if (!(sub.quantity && +sub.quantity > 0)) {
              sub.quantity = sub.initial_quantity
            }
            return sub
          })
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    },
    getStatus(status: ESuborderStatus) {
      return status && SUBORDER_STATUS[status] ? SUBORDER_STATUS[status] : ''
    },
    getStatusColor(status: ESuborderStatus) {
      return status && SUBORDER_COLORS[status] ? SUBORDER_COLORS[status] : '#27BBDC'
    },
    openEditMenu(event: MouseEvent, id: number | string) {
      this.pageX = event.pageX
      this.pageY = event.pageY
      this.tasks = this.tasks.map((task: any) => {
        if (id + '' === task.id + '') {
          task.showEditMenu = !task.showEditMenu
        } else {
          task.showEditMenu = false
        }
        return task
      })
    },
    getSuborderInputData(task: any) {
      return `№${task.id} ${task.name}`
    }
  },
  computed: {
    editMenuTopPosition() {
      return Math.min(window.innerHeight - 220, this.pageY) + 'px'
    },
    disableCancelOrder() {
      const disable =
        !(this.reason_for_cancellation && this.reason_for_cancellation !== 'Другое') &&
        !(this.reason_for_cancellation === 'Другое' && this.cancelOrderReason.length > 0)
      return disable
    },
    filteredTasks() {
      let tasks = this.tasks
      if (this.searchString) {
        tasks = tasks.filter((task: any) => {
          const worker = task.worker?.worker_full_name?.toLowerCase() || ''
          const id = (task.task_order?.id + '').toLowerCase()
          return worker.indexOf(this.searchString.toLowerCase()) !== -1 || id.indexOf(this.searchString.toLowerCase()) !== -1
        })
      }

      if (this.statusFilter && this.statusFilter !== 'status_all') {
        tasks = tasks.filter((task: any) => {
          return this.statusFilter === task.status
        })
      }

      return tasks.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
    }
  },
  created() {
    this.getTasks()

    if (this.$route.params.id) {
      this.$store
        .dispatch('order/getSingleOrder', this.$route.params.id)
        .then((response: any) => {
          this.order = response
        })
        .catch((e: any) => {
          console.error(e)
        })
    }

    this.$store
      .dispatch('order/getUnitTypes')
      .then((res: any) => {
        this.unitTypesList = res
      })
      .catch((e: any) => {
        console.error(e)
      })
  },
  watch: {
    '$route.params.id'() {
      this.getTasks()

      if (this.$route.params.id) {
        this.$store
          .dispatch('order/getSingleOrder', this.$route.params.id)
          .then((response: any) => {
            this.order = response
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
    }
  }
})
export default class OrderTasks extends Vue {}
