
import { Options, Vue } from 'vue-class-component'
import Loader from '@/components/Loader/Loader.vue'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UInput, UISelect, USwitch } from 'unit-uikit'

import useValidate from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@vuelidate/validators'
import { formatDate } from '@/utils/helpers'
import COUNTRIES from '@/utils/Countries'
import { SERVER_COUNTRIES } from '@/utils/consts'
import { jsonToFormData } from '@/utils/jsonToForm'

@Options({
  name: 'StaffInfo',
  components: {
    Loader,
    UInput,
    MainPageHeader,
    UISelect,
    USwitch
  },
  data() {
    return {
      v$: useValidate(),
      mediaURL: process.env.VUE_APP_URL + '/media/',
      isVerified: false,
      verificationStatus: null,
      galleryTab: 1,
      mainPhoto: '/img/icons/passport_empty.png',
      citizenshipData: [
        {
          id: SERVER_COUNTRIES.RUSSIA,
          name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
        },
        {
          id: SERVER_COUNTRIES.BELARUS,
          name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
        },
        {
          id: SERVER_COUNTRIES.ARMENIA,
          name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
        },
        {
          id: SERVER_COUNTRIES.KAZAKHSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
        },
        {
          id: SERVER_COUNTRIES.KYRGYZSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
        },
        {
          id: SERVER_COUNTRIES.UKRAINE,
          name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
        },
        {
          id: SERVER_COUNTRIES.DNR,
          name: COUNTRIES[SERVER_COUNTRIES.DNR]
        },
        {
          id: SERVER_COUNTRIES.LNR,
          name: COUNTRIES[SERVER_COUNTRIES.LNR]
        }
      ],
      genderOptions: [
        {
          id: 'Муж',
          name: 'Мужской'
        },
        {
          id: 'Жен',
          name: 'Женский'
        }
      ],
      passportOptions: [
        {
          id: 'Внутренний',
          name: 'Внутренний'
        },
        {
          id: 'Заграничный',
          name: 'Заграничный'
        }
      ],
      companyStaff: null,
      coworker: null,
      isPassportModalShow: false,
      loading: false
    }
  },
  validations() {
    return {
      coworker: {
        email: { email, required },
        username: { required },
        last_name: { required, maxLength: maxLength(150) },
        first_name: { required, maxLength: maxLength(150) },
        middle_name: { required, maxLength: maxLength(150) },
        personaldata: {
          inn: { required, minLength: minLength(12), maxLength: maxLength(14) },
          date_of_birth: { required }
        },
        passport_information: [
          {
            passport_series: { required },
            passport_number: { required },
            subdivision_code: { required },
            passport_whom_given: { required },
            date_of_issue: { required },
            place_of_birth: { required },
            registered_address: { required, maxLength: maxLength(250) }
          }
        ]
      },
      companyStaff: {
        company_name: { required },
        position: { required }
      }
    }
  },
  created() {
    this.getSingleCoworkerData()
  },
  methods: {
    formatDate,
    getSingleCoworkerData() {
      this.$store
        .dispatch('companies/getCoworker', this.$route.params.id)
        .then((res: any) => {
          this.coworker = res.worker
          this.companyStaff = res.company_staff
          if (res.worker.passport_information[0].passport_photo_registered_address) {
            this.mainPhoto = this.mediaURL + res.worker.passport_information[0].passport_photo_registered_address
          }
          if (this.isVerified !== res.verified) {
            this.isVerified = res.worker.verified
          }
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    getFullName(coworker: any) {
      return `${coworker.last_name} ${coworker.first_name} ${coworker.middle_name}`
    },
    getFormatedDate(date: string) {
      if (date) {
        let formatedDate: string[] = []
        formatedDate = date.split('-')
        return `${formatedDate[2]}.${formatedDate[1]}.${formatedDate[0]}`
      }
    },
    getPhotoSrc(link: string) {
      if (link.length === 0) return '/img/icons/passport_empty.png'
      return this.mediaURL + link
    },
    changeGalleryTab(tab: number) {
      this.galleryTab = tab
      if (tab === 1) {
        this.mainPhoto = this.mediaURL + this.coworker.passport_information[0].passport_photo_registered_address
      }
      if (tab === 2) {
        this.mainPhoto = this.mediaURL + this.coworker.passport_information[0].passport_photo_reversal
      }
      if (tab === 3) {
        this.mainPhoto = this.mediaURL + this.coworker.passport_information[0].selfie
      }
    },
    showPassportPhotoModal() {
      this.isPassportModalShow = true
    },
    reloadPassportPhoto(passportInfo: any, passportPhotoType: any, uploadedImg: any) {
      let data: any = {}
      data.username = this.coworker.username
      data.personal_data = {}

      if (passportInfo.type === 'Внутренний' && passportPhotoType === 1) {
        data.personal_data.inner_passport = {}
        data.personal_data.inner_passport.passport_photo_registered_address = uploadedImg
        data.personal_data.inner_passport.type = passportInfo.type
      } else if (passportInfo.type === 'Внутренний' && passportPhotoType === 2) {
        data.personal_data.inner_passport = {}
        data.personal_data.inner_passport.passport_photo_reversal = uploadedImg
        data.personal_data.inner_passport.type = passportInfo.type
      } else if (passportInfo.type === 'Внутренний' && passportPhotoType === 3) {
        data.personal_data.inner_passport = {}
        data.personal_data.inner_passport.passport_selfie = uploadedImg
        data.personal_data.inner_passport.type = passportInfo.type
      }
      if (passportInfo.type === 'Заграничный' && passportPhotoType === 2) {
        data.personal_data.foreign_passport = {}
        data.personal_data.foreign_passport.passport_photo_registered_address = uploadedImg
        data.personal_data.foreign_passport.type = passportInfo.type
      } else if (passportInfo.type === 'Заграничный' && passportPhotoType === 2) {
        data.personal_data.foreign_passport = {}
        data.personal_data.foreign_passport.passport_photo_reversal = uploadedImg
        data.personal_data.foreign_passport.type = passportInfo.type
      } else if (passportInfo.type === 'Заграничный' && passportPhotoType === 3) {
        data.personal_data.inner_passport = {}
        data.personal_data.inner_passport.passport_selfie = uploadedImg
        data.personal_data.inner_passport.type = passportInfo.type
      }

      const bodyFormData = jsonToFormData(data)

      this.loading = true

      this.$store
        .dispatch('companies/updateCoworker', { user: this.coworker.personaldata.user, formData: bodyFormData })
        .then(() => {
          this.loading = false
          this.getSingleCoworkerData()
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    },
    changeCoworkerData(key: string, value: string, isPersonalData: boolean) {
      this.v$.$validate()
      // console.log(this.v$.$error)
      // if (!this.v$.$error) {
      let data: any = {}

      if (key === 'username' || key === 'email' || key === 'first_name' || key === 'last_name' || key === 'middle_name') {
        data.username = this.coworker.username
        data.personal_data = {}
        data[key] = value
      }

      if (key === 'position') {
        data.company_id = this.companyStaff.company
        data.username = this.coworker.username
        data.personal_data = {}
        data[key] = value
      }

      if (!isPersonalData) {
        data[key] = value
      } else {
        data.username = this.coworker.username
        data.personal_data = {}
        let formatedData: string[] = []
        if (value === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.type = value
        }
        if (value === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.type = value
        }
        if (key === 'date_of_birth') {
          formatedData = value.split('.')
          data.personal_data.date_of_birth = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
        }
        if (key === 'passport_series' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.passport_series = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_series' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.passport_series = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_number' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.passport_number = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_number' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.passport_number = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_whom_given' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.passport_whom_given = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_whom_given' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.passport_whom_given = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'date_of_issue' && this.coworker.passport_information[0].type === 'Внутренний') {
          formatedData = value.split('.')
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.date_of_issue = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'date_of_issue' && this.coworker.passport_information[0].type === 'Заграничный') {
          formatedData = value.split('.')
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.date_of_issue = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_expires' && this.coworker.passport_information[0].type === 'Внутренний') {
          formatedData = value.split('.')
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.passport_expires = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_expires' && this.coworker.passport_information[0].type === 'Заграничный') {
          formatedData = value.split('.')
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.passport_expires = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'place_of_birth' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.place_of_birth = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'place_of_birth' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.place_of_birth = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'registered_address' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.registered_address = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'registered_address' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.registered_address = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'subdivision_code' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.subdivision_code = value.replace('-', '')
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'subdivision_code' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.subdivision_code = value.replace('-', '')
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (
          value !== 'Заграничный' &&
          value !== 'Внутренний' &&
          key !== 'passport_series' &&
          key !== 'passport_number' &&
          key !== 'passport_whom_given' &&
          key !== 'date_of_issue' &&
          key !== 'date_of_birth' &&
          key !== 'passport_expires' &&
          key !== 'place_of_birth' &&
          key !== 'registered_address'
        ) {
          data.username = this.coworker.username
          data.personal_data[key] = value
        }
      }
      this.loading = true

      this.$store
        .dispatch('companies/updateCoworker', {
          user: this.coworker.personaldata.user,
          formData: data
        })
        .then(() => {
          this.loading = false
        })
        .catch((e: any) => {
          console.error(e)
          this.loading = false
        })
    }
  },
  computed: {
    getVerificationStatus() {
      return this.isVerified ? 'Пройдена' : 'Не пройдена'
    }
  },
  watch: {
    isVerified() {
      this.loading = true

      this.$store
        .dispatch('companies/updateCoworker', {
          user: this.coworker.personaldata.user,
          formData: {
            username: this.coworker.username,
            verified: this.isVerified,
            personal_data: {}
          }
        })
        .then(() => {
          this.loading = false
          this.getSingleCoworkerData()
        })
        .catch((e: any) => {
          console.error(e)
          this.loading = false
          console.error(e)
        })
    }
  }
})
export default class StaffInfo extends Vue {}
