
import { Options, Vue } from 'vue-class-component'
import { UInput, UButton, UISelect, UTable } from 'unit-uikit'
import { formatDate } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import COUNTRIES from '@/utils/Countries'
import { SERVER_COUNTRIES } from '@/utils/consts'
import SortItem from '@/components/Sort/SortItem.vue'

@Options({
  name: 'VerifiedWorkers',
  components: {
    UTable,
    UInput,
    UButton,
    UISelect,
    Loader,
    SortItem
  },
  data() {
    return {
      citizenshipData: [
        {
          id: SERVER_COUNTRIES.RUSSIA,
          name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
        },
        {
          id: SERVER_COUNTRIES.BELARUS,
          name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
        },
        {
          id: SERVER_COUNTRIES.ARMENIA,
          name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
        },
        {
          id: SERVER_COUNTRIES.KAZAKHSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
        },
        {
          id: SERVER_COUNTRIES.KYRGYZSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
        },
        {
          id: SERVER_COUNTRIES.UKRAINE,
          name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
        },
        {
          id: SERVER_COUNTRIES.DNR,
          name: COUNTRIES[SERVER_COUNTRIES.DNR]
        },
        {
          id: SERVER_COUNTRIES.LNR,
          name: COUNTRIES[SERVER_COUNTRIES.LNR]
        }
      ],
      filterString: '',
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      loading: true,
      selectedCitizenship: '',
      workers: [],
      searchFirstName: '',
      searchLastName: '',
      searchPhone: '',
      searchINN: '',
      searchByCompany: '',
      sortingDateCreated: null, // null, true, false
      sortingCompleteData: null // null, true, false
    }
  },
  created() {
    this.loadContent(false)
  },
  methods: {
    formatDate,
    sortWorkers(state: any, type: string) {
      if (type === 'dateCreated') {
        this.sortingCompleteData = null
        this.sortingDateCreated = state
        this.createFilterString()
        return
      }
      if (type === 'completeData') {
        this.sortingDateCreated = null
        this.sortingCompleteData = state
        this.createFilterString()
      }
    },
    loadWorkersWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.workers = []
      this.loadWorkersOnScroll()
    },
    getFullName(worker: any) {
      return `${worker.last_name || ''} ${worker.first_name || ''} ${worker.middle_name || ''}`
    },
    getCitizenship(citizenship: string) {
      return COUNTRIES[citizenship]
    },
    getUpdateStatus(worker: any) {
      if (worker.complete_data) {
        return 'Полные данные'
      } else {
        return 'Неполные данные'
      }
    },
    createFilterString() {
      let queryParams = ''

      if (this.sortingDateCreated !== null) {
        queryParams = `&sorting=${this.sortingDateCreated ? '' : '-'}personaldata__last_updated`
      }
      if (this.sortingCompleteData !== null) {
        queryParams = `&sorting=${this.sortingCompleteData ? '' : '-'}complete_data`
      }

      if (this.searchFirstName) {
        queryParams += `&first_name=${this.searchFirstName}`
      }
      if (this.searchLastName) {
        queryParams += `&last_name=${this.searchLastName}`
      }
      if (this.searchPhone) {
        queryParams += `&phone=${this.searchPhone.replaceAll('-', '')}`
      }
      if (this.searchINN) {
        queryParams += `&inn=${this.searchINN}`
      }
      if (this.searchByCompany) {
        queryParams += `&company=${this.searchByCompany}`
      }
      if (this.selectedCitizenship) {
        queryParams += `&citizenship=${this.selectedCitizenship}`
      }

      this.filterString = queryParams
      this.loadWorkersWithParams()
    },
    clearFilters() {
      this.searchFirstName = ''
      this.searchLastName = ''
      this.searchPhone = ''
      this.searchINN = ''
      this.searchByCompany = ''
      this.selectedCitizenship = ''
      this.filterString = ''
      this.loadWorkersWithParams()
    },
    async loadWorkersOnScroll() {
      if (this.workers.length < this.scrollData.count) {
        this.scrollData.page++

        const response = await this.$store.dispatch(
          'worker/getSupportWorker',
          `?page=${this.scrollData.page}&count=${this.scrollData.size}&verified=${
            this.$route.name === 'workers.verified' ? 'True' : 'False'
          }` + this.filterString
        )

        const data = response
        if (data.results && data.count) {
          this.scrollData.count = data.count
          const workers = data.results
          this.workers.push(...workers)
        }
      }
    },
    loadContent(isTabChange: boolean) {
      this.loading = true

      if (isTabChange) {
        this.scrollData.page = 1
      }

      let queryParams = ''

      if (this.sortingDateCreated !== null) {
        queryParams = `&sorting=${this.sortingDateCreated ? '' : '-'}personaldata__last_updated`
      }
      if (this.sortingCompleteData !== null) {
        queryParams = `&sorting=${this.sortingCompleteData ? '' : '-'}complete_data`
      }

      this.$store
        .dispatch(
          'worker/getSupportWorker',
          `?page=${this.scrollData.page}&count=${this.scrollData.size}&verified=${
            this.$route.name === 'workers.verified' ? 'True' : 'False'
          }` + queryParams
        )
        .then((res: any) => {
          this.scrollData.count = res.count
          this.workers = res.results
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    }
  },
  watch: {
    $route(to, from) {
      this.loadContent(true)
    }
  }
})
export default class WorkersList extends Vue {}
