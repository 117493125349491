
import { Options, Vue } from 'vue-class-component'
import { UInput, UButton, UISelect, UTable } from 'unit-uikit'
import { APIOrders } from '@/interfaces/main'
import { formatPrice } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import { EOrderStatus } from '@/types/api-values'
import { ORDER_STATUS, ORDER_COLORS } from '@/utils/consts'
import Tooltip from '@/components/Tooltips/Tooltips.vue'

@Options({
  name: 'orders',
  components: {
    UTable,
    UInput,
    UButton,
    UISelect,
    Loader,
    Tooltip
  },
  data() {
    return {
      loading: true,
      searchString: '',
      orders: [],
      selectedProject: null,
      projectOptions: [],
      selectedObject: null,
      objectOptions: [],
      allObjects: [],
      showAllFilters: false,
      filterString: '',
      companyTitle: '',
      suborderNumber: '',
      orderNumber: '',
      orderTitle: '',
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 10,
        page: 1
      }
    }
  },
  created() {
    this.selectedProject = 'allProjects'
    this.selectedObject = 'allObjects'

    this.$store
      .dispatch('main/getProject')
      .then((res: any) => {
        const projects = res

        this.projectOptions = [
          {
            id: 'allProjects',
            name: 'Показать все'
          },
          ...projects
        ]
      })
      .catch((e: any) => {
        console.error(e)
      })

    this.$store
      .dispatch('main/getObject')
      .then((res: any) => {
        const objects = res
        this.allObjects = res

        this.objectOptions = [
          {
            id: 'allObjects',
            name: 'Показать все'
          },
          ...objects
        ]
      })
      .catch((e: any) => {
        console.error(e)
      })

    this.$store
      .dispatch('order/getOrder', `?page_size=${this.scrollData.size}`)
      .then((response: any) => {
        this.scrollData.count = response.count || 0
        const orders = response.results
        this.orders = orders
          ? orders.sort((a: any, b: any) => {
              return new Date(b.created).valueOf() - new Date(a.created).valueOf()
            })
          : []
        this.loading = false
      })
      .catch((e: any) => {
        this.loading = false
        console.error(e)
      })
  },
  methods: {
    loadOrdersWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.orders = []
      this.loadOrders()
    },
    async loadOrders() {
      if (this.orders.length < this.scrollData.count) {
        this.scrollData.page++

        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}` + this.filterString

        const response = await this.$store.dispatch('order/getOrder', queryString)

        const data = response
        if (data.results && data.count) {
          this.scrollData.requestCount = data.count
          const orders = data.results
          const result = orders
            ? orders.sort((a: any, b: any) => {
                return new Date(b.created).valueOf() - new Date(a.created).valueOf()
              })
            : []
          this.orders.push(...result)
        }
      }
    },

    getFormatDate(date: string) {
      if (date) {
        const formatDateArr = date.slice(0, 10).replaceAll('-', '.').split('.').reverse()
        if (formatDateArr && formatDateArr[2]) {
          formatDateArr[2] = formatDateArr[2].slice(2)
        }
        return formatDateArr.join('.')
      }
    },
    getStatus(status: EOrderStatus) {
      return status && ORDER_STATUS[status] ? ORDER_STATUS[status] : ''
    },
    getStatusColor(status: EOrderStatus) {
      return status && ORDER_COLORS[status] ? ORDER_COLORS[status] : '#27BBDC'
    },
    getObject(id: number) {
      const order = this.orders.filter((o: APIOrders) => o.id === id)
      const obj = order && order[0]?.object
      const name = obj?.name
      return name || 'Нет объекта'
    },
    getProject(id: number) {
      const order = this.orders.filter((o: APIOrders) => o.id === id)
      const project = order && order[0]?.object?.project
      const name = project?.name
      return name || 'Нет проекта'
    },
    getBudget(id: number) {
      const order = this.orders.filter((o: APIOrders) => o.id === id)
      const budget = order && (order[0]?.budget_total || order[0]?.budget)
      return budget ? formatPrice(budget) : '0,00'
    },
    createFilterString() {
      let queryParams = ''
      if (this.searchString) {
        queryParams += `&last_name=${this.searchString}`
      }
      if (this.companyTitle) {
        queryParams += `&company_name=${this.companyTitle}`
      }
      if (this.suborderNumber) {
        queryParams += `&suborder_id=${this.suborderNumber}`
      }
      if (this.orderNumber) {
        queryParams += `&order_id=${this.orderNumber}`
      }
      if (this.orderTitle) {
        queryParams += `&order_suborder_name=${this.orderTitle}`
      }

      if (this.selectedProject && this.selectedProject !== 'allProjects') {
        queryParams += `&project_id=${this.selectedProject}`
      }

      if (this.selectedObject && this.selectedObject !== 'allObjects') {
        queryParams += `&object_id=${this.selectedObject}`
      }

      this.filterString = queryParams
      this.loadOrdersWithParams()
    },
    clearFilters() {
      this.companyTitle = ''
      this.suborderNumber = ''
      this.orderNumber = ''
      this.orderTitle = ''
      this.searchString = ''
      this.selectedProject = 'allProjects'
      this.selectedObject = 'allObjects'
      this.filterString = ''
      this.loadOrdersWithParams()
    }
  },
  watch: {
    selectedProject() {
      if (this.selectedProject !== 'allProjects') {
        let isObjectSelectFlag = false
        const objects = this.allObjects.filter((item: any) => {
          if (!isObjectSelectFlag) {
            isObjectSelectFlag = item.id + '' === this.selectedObject + '' && item.project?.id + '' === this.selectedProject + ''
          }
          return item.project?.id + '' === this.selectedProject + ''
        })
        this.objectOptions = [
          {
            id: 'allObjects',
            name: 'Показать все'
          },
          ...objects
        ]
        if (!isObjectSelectFlag) {
          this.selectedObject = 'allObjects'
        }
      } else {
        const objects = this.allObjects
        this.selectedObject = 'allObjects'
        this.objectOptions = [
          {
            id: 'allObjects',
            name: 'Показать все'
          },
          ...objects
        ]
      }
    },
    selectedObject() {
      if (this.selectedObject !== 'allObjects') {
        const _object = this.allObjects.filter((item: any) => {
          return item.id + '' === this.selectedObject + ''
        })
        const projectId = _object && _object[0] && _object[0].project?.id
        if (projectId && this.selectedProject + '' !== projectId + '') {
          this.selectedProject = projectId
        }
      }
    }
  }
})
export default class Orders extends Vue {}
