
import { Options, Vue } from 'vue-class-component'
import { UISelect, UButton } from 'unit-uikit'

import { formatPrice } from '@/utils/helpers'
import { EOrderStatus } from '@/types/api-values'
import { ORDER_STATUS } from '@/utils/consts'

@Options({
  name: 'order',
  components: {
    UISelect,
    UButton
  },
  data() {
    return {
      loaded: false,
      showCancelPopup: false,
      signedError: false,
      cannotCancelOrder: false,
      order: {},
      orderStatuses: [
        {
          id: EOrderStatus.active,
          name: ORDER_STATUS[EOrderStatus.active]
        },
        {
          id: EOrderStatus.cancelled,
          name: ORDER_STATUS[EOrderStatus.cancelled]
        },
        {
          id: EOrderStatus.finished,
          name: ORDER_STATUS[EOrderStatus.finished]
        }
      ],
      pageReady: false,
      EOrderStatus
    }
  },
  created() {
    this.$store
      .dispatch('order/getSingleOrder', this.$route.params.id)
      .then((response: any) => {
        this.order = response
        this.loaded = true
        setTimeout(() => {
          this.pageReady = true
        }, 0)
      })
      .catch((e: any) => {
        this.pageReady = true
        console.error(e)
      })
    this.showCancelPopup = false
  },
  methods: {
    getQuantity() {
      if (this.order.quantity && +this.order.quantity > 0) {
        return this.order.quantity
      }
      return this.order.initial_quantity
    },
    getUnitType() {
      const _unit = this.order.unit_types && this.order.unit_types.name
      return _unit || this.order?.unit_types?.name
    },
    getEquipment() {
      if (this.order.equipment) {
        if (Array.isArray(this.order.equipment)) {
          return this.order.equipment.length ? this.order.equipment.map((item: any) => item.name).join(', ') : 'Нет'
        }
        return this.order.equipment || 'Нет'
      }
      return 'Нет'
    },
    getSingleOrderData(id: any) {
      if (id) {
        this.$store
          .dispatch('order/getSingleOrder', id)
          .then((response: any) => {
            this.order = response
            this.loaded = true
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
    },
    formatDateTime(date: string) {
      if (date) {
        return date.split('-').reverse().join('.')
      }
    },
    getOrderEquipment(equipment: any) {
      if (equipment.length === 0) return 'Нет'
      return equipment
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    formatTemplateName(name: string) {
      if (name) {
        const chars = name.split('')
        chars[0] = chars[0].toUpperCase()
        return chars.join('').replaceAll('_', ' ')
      }
      return ''
    },
    cancelOrder(id: any) {
      this.$store
        .dispatch('order/getOrderDocuments', id)
        .then((response: any) => {
          const data = response
          const result = data.some((item: any) => {
            const value = [item.agreement?.status?.toUpperCase(), item.task_order?.status?.toUpperCase()].includes('SIGNED')
            return value
          })

          if (!result) {
            this.signedError = false

            this.$store
              .dispatch('order/updateSingleOrder', { id: id, data: { status: EOrderStatus.cancelled } })
              .then(() => {
                this.getSingleOrderData(this.$route.params.id)
                this.showCancelPopup = false
              })
              .catch((e: any) => {
                this.signedError = true
                console.error(e)
              })
          } else {
            this.signedError = true
          }
        })
        .catch(() => {
          this.cannotCancelOrder = true
        })
    },
    closePopup() {
      this.signedError = false
      this.showCancelPopup = false

      this.$store
        .dispatch('order/getSingleOrder', this.$route.params.id)
        .then((response: any) => {
          this.order = response
          this.loaded = true
          setTimeout(() => {
            this.pageReady = true
          }, 0)
        })
        .catch((e: any) => {
          this.pageReady = true
          console.error(e)
        })
    },
    closeErrorPopup() {
      this.showCancelPopup = false
      this.cannotCancelOrder = false

      this.$store
        .dispatch('order/getSingleOrder', this.$route.params.id)
        .then((response: any) => {
          this.order = response
          this.loaded = true
          setTimeout(() => {
            this.pageReady = true
          }, 0)
        })
        .catch((e: any) => {
          this.pageReady = true
          console.error(e)
        })
    }
  },
  watch: {
    'order.status'(val, prevVal) {
      if (val === EOrderStatus.active && prevVal) {
        return
      }
      if (this.pageReady) {
        if (prevVal && val === EOrderStatus.cancelled) {
          this.showCancelPopup = true
        } else if (this.$route.params.id) {
          this.$store
            .dispatch('order/updateSingleOrder', { id: this.$route.params.id, data: { status: val } })
            .then(() => {
              this.getSingleOrderData(this.$route.params.id)
            })
            .catch((e: any) => {
              console.error(e)
            })
        }
      }
    }
  }
})
export default class OrderInfo extends Vue {}
