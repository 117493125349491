
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'

@Options({
  components: {
    UButton
  },
  props: {
    show: Boolean,
    title: String,
    textOk: String,
    successAction: Function
  }
})
export default class SuccessModal extends Vue {}
