
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    user: Object
  },
  methods: {
    getUserName(user: any) {
      return `${user.last_name || ''} ${user.first_name || ''} ${user.middle_name || ''}`
    },
    getUserPhone(user: any) {
      return `${user.username || ''}`
    },
    getUserINN(user: any) {
      return `${user.inn || ''}`
    }
  }
})
export default class UsersTableItem extends Vue {}
