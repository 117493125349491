
import { Options, Vue } from 'vue-class-component'
import { UInput } from 'unit-uikit'

@Options({
  name: 'CompanyBankInfo',
  components: {
    UInput
  },
  data() {
    const info = this.info as any
    return {
      company: info?.company,
      bik: info?.bik,
      bikError: '',
      bank: info?.bank,
      bankError: '',
      checking_account: info?.account,
      checking_accountError: '',
      correspondent_account: info?.correspondent,
      correspondent_accountError: ''
    }
  },
  props: {
    info: { type: Object },
    readonly: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    updateCompanyInfo(field: string) {
      if (this.readonly) {
        return
      }

      this[field + 'Error'] = ''
      const id = this.$route?.params?.id
      const data = {
        bik: this.bik,
        bank: this.bank,
        checking_account: this.checking_account,
        correspondent_account: this.correspondent_account,
        company: this.company
      }

      return this.$store
        .dispatch('companies/updateEntityAccountDetails', { id, data })
        .then(() => {
          this[field + 'Error'] = ''
        })
        .catch((err: any) => {
          console.error(err)
          const data = err.response.data
          const errText = data[field]
          this[field + 'Error'] = errText && errText.join(', ')
        })
    }
  }
})
export default class CompanyBankInfo extends Vue {}
