
import { Options, Vue } from 'vue-class-component'
import { formatPrice } from '@/utils/helpers'
import Tooltip from '@/components/Tooltips/Tooltips.vue'

@Options({
  name: 'PriceItem',
  components: {
    Tooltip
  },
  props: {
    price: {
      type: [String, Number],
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formatPrice
    }
  },
})

export default class PriceItem extends Vue {}
