
import { ref } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { formatDate } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import debounce from '@/utils/debounce'
import { UAlert, UInput, UTable } from 'unit-uikit'

@Options({
  name: 'VerifiedCompanies',
  components: {
    Loader,
    UAlert,
    UInput,
    UTable
  },
  data() {
    return {
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      loading: true,
      entities: [],
      searchString: ''
    }
  },
  methods: {
    formatDate,
    getFullName(worker: any) {
      return `${worker?.last_name || ''} ${worker?.first_name || ''} ${worker?.middle_name || ''}`
    },
    loadEntitiesWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.entities = []
      this.loadEntities()
    },
    async loadEntities() {
      if (this.entities.length < this.scrollData.count) {
        this.scrollData.page++

        let queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&verified=True`

        if (this.searchString) {
          queryString += `&name=${this.searchString}`
        }

        const response = await this.$store.dispatch('companies/getEntitiesList', queryString)

        const data = response
        if (data.results && data.count) {
          this.scrollData.count = data.count
          const entities = data.results
          entities.forEach((item: any) => {
            item.needToCheck = ref(false)
            this.checkNewProcutions(item)
          })
          this.entities.push(...entities)
        }
      }
    },
    showAlert(company: any) {
      company.needToCheck.value = true
    },
    checkNewProcutions(company: any) {
      const id = company.director?.company
      if (id) {
        this.$store
          .dispatch('companies/getCompanyProcurations', { id: id, status: '&status=on_check' })
          .then((res: any) => {
            if (res.results?.length) {
              this.showAlert(company)
            }
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
    },
    loadContent() {
      this.loading = true

      this.$store
        .dispatch('companies/getEntitiesList', `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&verified=True`)
        .then((res: any) => {
          this.scrollData.count = res.count
          const results = res.results
          results.forEach((item: any) => {
            item.needToCheck = ref(false)
            this.checkNewProcutions(item)
          })
          this.entities = results
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    }
  },
  created() {
    this.loadContent()
  },
  watch: {
    searchString() {
      if (this.debounceloadEntities) {
        this.debounceloadEntities()
      } else {
        this.debounceloadEntities = debounce(() => {
          this.loadEntitiesWithParams()
        })
        this.debounceloadEntities()
      }
    }
  }
})
export default class VerifiedCompanies extends Vue {}
