
import { Options, Vue } from 'vue-class-component'

import { UButton, UInput } from 'unit-uikit'

import NewUserModal from '@/components/Modals/NewUserModal.vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import SuccessModal from '@/components/Modals/SuccessModal.vue'

import UsersTableItem from './Components/UsersTableItem.vue'

import debounce from '@/utils/debounce'

@Options({
  name: 'CompanyAccesses',
  components: {
    UsersTableItem,
    UInput,
    UButton,
    NewUserModal,
    SuccessModal,
    ErrorModal
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ready: false,
      searcUser: '',
      infiniteLoader: false,
      selectedUsers: [],
      users: [],
      showModal: false,
      successCreated: false,
      showErrorPopup: false
    }
  },
  methods: {
    openSuccessModal(error: any) {
      this.closeNewUserModal()
      this.getUsersList()
      if (error) {
        this.showErrorPopup = true
      } else {
        this.successCreated = true
      }
    },
    openNewUserModal() {
      this.showModal = true
    },
    closeNewUserModal() {
      this.showModal = false
    },
    getUsersList() {
      let queryString = ''
      if (this.searcUser) {
        queryString += `?username=${this.searcUser}`
      }

      const companyId = this.$route.params.id
      const queryCompanyString = queryString ? queryString + `&company_id=${companyId}` : `?company_id=${companyId}`

      this.$store
        .dispatch('companies/getCustomersList', queryString)
        .then((res: any) => {
          const data = res
          this.users = data || []
        })
        .catch((err: any) => {
          console.error(err)
        })

      this.$store
        .dispatch('companies/getCustomersList', queryCompanyString)
        .then((res: any) => {
          const data = res
          this.selectedUsers = data || []
        })
        .catch((err: any) => {
          console.error(err)
        })
    },
    removeUser(user: any) {
      const companyId = this.$route.params.id

      this.$store
        .dispatch('companies/removeUser', {
          company_id: companyId,
          user_id: user.id
        })
        .then(() => {
          this.getUsersList()
        })
        .catch(() => {
          this.showErrorPopup = true
        })
    },
    addUser(user: any) {
      const companyId = this.$route.params.id

      this.$store
        .dispatch('companies/addUser', {
          company_id: companyId,
          user_id: user.id
        })
        .then(() => {
          this.getUsersList()
        })
        .catch(() => {
          this.showErrorPopup = true
        })
    }
  },
  created() {
    this.getUsersList()
  },
  watch: {
    searcUser() {
      if (this.debounceGetUsersList) {
        this.debounceGetUsersList()
      } else {
        this.debounceGetUsersList = debounce(() => {
          this.getUsersList()
        })
        this.debounceGetUsersList()
      }
    }
  }
})
export default class CompanyAccesses extends Vue {}
