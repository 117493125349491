
import { Options, Vue } from 'vue-class-component'
import { UInput } from 'unit-uikit'

@Options({
  name: 'CompanyConditions',
  components: {
    UInput
  },
  data() {
    const info = this.info as any
    return {
      monthly_limit_without_tax: info?.monthly_limit_without_tax,
      monthly_limit_without_taxError: '',
      unitpay_commission: info?.unitpay_commission,
      unitpay_commissionError: '',
      monthly_limit_with_tax: info?.monthly_limit_with_tax,
      monthly_limit_with_taxError: '',
      agreement_duration: info?.agreement_duration,
      agreement_durationError: ''
    }
  },
  props: {
    info: { type: Object },
    readonly: {
      type: Boolean,
      default: true
    }
  },
  created() {},
  methods: {
    updateCompanyMainInfo(field: string) {
      if (this.readonly) {
        return
      }

      this[field + 'Error'] = ''
      const id = this.$route?.params?.id
      const data = {
        [field]: this[field] + ' 00:00:00'
      }

      return this.$store
        .dispatch('companies/updateEntityRecord', { id: id, data: data })
        .then(() => {
          this[field + 'Error'] = ''
        })
        .catch((err: any) => {
          console.error(err)
          const data = err.response.data
          const errText = data[field]
          this[field + 'Error'] = errText && errText.join(', ')
        })
    },
    updateCompanyInfo(field: string) {
      this[field + 'Error'] = ''
      const id = this.$route?.params?.id
      const data = {
        [field]: this[field]
      }
      return this.$store
        .dispatch('companies/updateEntityAccountConditions', { id: id, data: data })
        .then(() => {
          this[field + 'Error'] = ''
        })
        .catch((err: any) => {
          console.error(err)
          const data = err.response.data
          const errText = data[field]
          this[field + 'Error'] = errText && errText.join(', ')
        })
    }
  }
})
export default class CompanyConditions extends Vue {}
