
import { Options, Vue } from 'vue-class-component'
import { UInput, UButton } from 'unit-uikit'

@Options({
  components: {
    UInput,
    UButton
  },
  data() {
    const username = this.phone || ''
    return {
      loading: false,
      usernameError: '',
      username
    }
  },
  props: {
    show: Boolean,
    title: String,
    message: String,
    phone: String,
    successBtnLabel: {
      type: String,
      default: 'Сохранить'
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    updatePhone() {
      const id = this.$route.params.id
      this.loading = true

      this.usernameError = ''
      const username = this.username.replace(/-/g, '')

      this.$store
        .dispatch('worker/changeWorkerVerificationStatus', { id: id, data: { username } })
        .then(() => {
          this.loading = false
          this.$emit('successModal')
        })
        .catch((e: any) => {
          const data = e.response.data
          const errText = data.username && data.username.join(', ')
          this.loading = false

          if (errText) {
            this.usernameError = errText
          } else {
            this.$emit('successModal', e)
          }
        })
    }
  },
  computed: {
    addBtnDisabled() {
      return this.username.replace(/-/g, '').length !== 12
    }
  }
})
export default class ChangePhoneModal extends Vue {}
