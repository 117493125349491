
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'

@Options({
  components: {
    UButton
  },
  emits: ['addNewDoc', 'updateDoc', 'deleteDoc'],
  props: {
    title: String,
    templates: {
      type: Array,
      default: []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getFileUrl(url: string) {
      const mediaURL = process.env.VUE_APP_URL + '/media/'
      return `${mediaURL}${url}`
    },
    updateDoc(item: any) {
      this.$emit('updateDoc', item)
    },
    addNewDoc() {
      this.$emit('addNewDoc')
    },
    deleteDoc(item: any) {
      this.$emit('deleteDoc', item)
    }
  }
})
export default class TemplateItem extends Vue {}
