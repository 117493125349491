
import { API } from '@/utils/API'
import { Options, Vue } from 'vue-class-component'
import { EDocsStatus } from '@/types/api-values'

@Options({
  components: {},
  props: {
    title: String,
    document: Object,
    orderStatus: String,
    status: String
  },
  methods: {
    EDocsStatus() {
      return EDocsStatus
    },
    getCertificateLink(document: any) {
      const file = document.certificate
      if (file) {
        const url = this.getDownloadLink(file)
        if (url) {
          return url
        }
      }
      return document?.deeplink
    },
    checkStatus(status: any) {
      const _status = status?.toLowerCase() || ''
      return _status.toLowerCase() === EDocsStatus.signed
    },
    getTitle(status: any) {
      if (status) {
        switch (status.toLowerCase()) {
          case EDocsStatus.converting_error:
            return 'Создать'
          case EDocsStatus.not_signed:
            return 'Подписать'
          case EDocsStatus.signed:
            return '(Сертификат)'
          default:
            return ''
        }
      }
      return ''
    },
    getDocumentStatus(status: any) {
      if (status) {
        switch (status.toLowerCase()) {
          case EDocsStatus.not_signed:
            return 'document__not-signed'
          // case EDocsStatus.exists:
          case EDocsStatus.active:
          case EDocsStatus.signed:
            return 'document__signed'
          default:
            return 'document__empty'
        }
      }
      return 'document__empty'
    },
    getDownloadLink(file: string) {
      if (file) {
        return API.GET_FILE(file)
      }
      return ''
    }
  },
  computed: {
    showBtn() {
      const status = this.status && this.status.toLowerCase()
      return this.orderStatus !== 'finished' || status === EDocsStatus.signed
    }
  }
})
export default class DocItem extends Vue {}
