
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { UInput, UButton } from 'unit-uikit'

import useValidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

@Options({
  components: {
    UInput,
    UButton
  },
  data() {
    return {
      v$: useValidate(),
      showSMSCode: false,
      userAlreadyExists: false,
      timer: 60,
      timeout: null,
      user: {
        phone: '',
        name: '',
        code: ''
      },
      codeError: false
    }
  },
  methods: {
    ...mapActions({
      auth: 'auth/login'
    }),
    sendSMS() {
      const user = this.user
      this.auth(user).then(
        () => {
          // Move to page for input sms code
          this.startTimer()
        },
        (error: unknown) => {
          console.log('error', error)
        }
      )
    },
    login() {
      this.v$.user.phone.$touch()

      if (this.v$.user.phone.$invalid) {
        return
      }

      const user = this.user
      if (user.phone.length === 17) user.phone = user.phone.slice(0, 16)

      this.$store
        .dispatch('auth/login', user)
        .then(
          () => {
            // Move to page for input sms code
            this.showSMSCode = true
            this.userAlreadyExists = false
            this.startTimer()
          },
          (error: unknown) => {
            this.userAlreadyExists = true
          }
        )
        .catch((err: any) => {
          this.userAlreadyExists = true
        })
    },
    loginConfirm() {
      const user = this.user

      this.$store.dispatch('auth/loginConfirm', user).then(
        () => {
          // Move to page for input sms code
          this.$router.push('/')
        },
        (error: any) => {
          this.codeError = true
          console.log('error', error.response.data)
        }
      )
    },
    startTimer() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timer = 60
      this.timeout = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
      }, 1000)
    },
    handleKeyDown(evt: any) {
      if (evt.keyCode === 8 && (evt.target.selectionStart === 2 || evt.target.selectionStart === 3)) {
        evt.preventDefault()
      }
    },
    handlePaste(event: any) {
      event.preventDefault()
      let _pastePhone = (event.clipboardData || (window as any).clipboardData).getData('text').replace(/[^0-9]/g, '')

      if (_pastePhone.length >= 10 && _pastePhone.startsWith('8')) {
        _pastePhone = '7' + _pastePhone.slice(1)
      }

      this.user.phone = _pastePhone
    }
  },
  computed: {
    formatPhone() {
      if (this.user.phone.length === 0) {
        this.user.phone = '+7'
      }

      return this.user.phone
    }
  },
  validations() {
    return {
      user: {
        phone: { required, minLength: minLength(16) }
      }
    }
  },
  watch: {
    'user.code'() {
      this.codeError = false
    },
    'user.phone'() {
      this.userAlreadyExists = false
    }
  }
})
export default class Login extends Vue {
  @Prop({ type: Boolean, default: false }) readonly hideActions!: boolean
}
