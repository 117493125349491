
import { Options, Vue } from 'vue-class-component'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'

@Options({
  name: 'orders',
  components: {
    MainPageHeader
  },
  data() {
    return {
      title: 'Реестр заказов',
      ready: false
    }
  }
})

export default class Orders extends Vue {}
