
import { Options, Vue } from 'vue-class-component'
import { UISelect, UModal, UButton, UTable } from 'unit-uikit'
import DocItem from './DocItem.vue'
import Loader from '@/components/Loader/Loader.vue'

import { API } from '@/utils/API'
import COUNTRIES, { showPassportFieldByCountry } from '@/utils/Countries'
import { EDocsStatus } from '@/types/api-values'
import { DOCS_STATUS } from '@/utils/consts'

@Options({
  name: 'order',
  components: {
    UTable,
    UISelect,
    DocItem,
    Loader,
    UModal,
    UButton
  },
  data() {
    return {
      loading: true,
      showModal: false,
      termitedDocId: null,
      orderId: this.$route.params.id,
      order: null,
      documents: [],
      allDocsSigned: true,
      docStatuses: [
        {
          id: EDocsStatus.signed,
          name: DOCS_STATUS[EDocsStatus.signed]
        },
        {
          id: EDocsStatus.not_signed,
          name: DOCS_STATUS[EDocsStatus.not_signed]
        },
        {
          id: EDocsStatus.active,
          name: DOCS_STATUS[EDocsStatus.active]
        },
        {
          id: EDocsStatus.finished,
          name: DOCS_STATUS[EDocsStatus.finished]
        },
        {
          id: EDocsStatus.terminated,
          name: DOCS_STATUS[EDocsStatus.terminated]
        },
        // {
        //   id: EDocsStatus.exists,
        //   name: DOCS_STATUS[EDocsStatus.exists]
        // },
        {
          id: EDocsStatus.converting_error,
          name: DOCS_STATUS[EDocsStatus.converting_error]
        }
      ]
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    cancelModal() {
      this.termitedDocId = null
      this.showModal = false
    },
    terminateDoc() {
      const doc = this.termitedDocId
      if (doc) {
        this.$store
          .dispatch('order/updateSingleDoc', { doc: doc, data: { status: EDocsStatus.terminated } })
          .then(() => {
            this.getDocsInfo()
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
      this.cancelModal()
    },
    updateDocStatus($event: any, doc: any) {
      const status = $event.target.value
      if (status === EDocsStatus.terminated) {
        this.termitedDocId = doc
        this.showModal = true
      } else {
        if (status && doc) {
          return this.$store.dispatch('order/updateSingleDoc', { doc: doc, data: { status } })
        }
      }
    },
    getDocsInfo() {
      this.$store
        .dispatch('order/getOrderDocuments', this.orderId)
        .then((res: any) => {
          this.documents = res
          res.forEach((doc: any) => {
            if (doc.agreement_status && doc.agreement_status[0] && doc.agreement_status[0].status !== 'SIGNED') {
              this.allDocsSigned = false
            }
            if (doc.task_order_status && doc.task_order_status[0] && doc.task_order_status[0].status !== 'SIGNED') {
              this.allDocsSigned = false
            }
            if (
              doc.acceptance_certificate_status &&
              doc.acceptance_certificate_status[0] &&
              doc.acceptance_certificate_status[0].status !== 'SIGNED'
            ) {
              this.allDocsSigned = false
            }
          })
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    showPassportFieldByCountry(field: string, citizenship: any) {
      return showPassportFieldByCountry(field, citizenship)
    },
    getCitizenship(citizenship: any) {
      return COUNTRIES[citizenship]
    },
    getDownloadLink(file: string) {
      if (file) {
        return API.GET_FILE(file)
      }
      return ''
    }
  },
  created() {
    if (this.orderId) {
      this.loading = true

      this.$store
        .dispatch('order/getSingleOrder', this.orderId)
        .then((response: any) => {
          this.order = response
          this.loading = false
        })
        .catch((e: any) => {
          console.error(e)
          this.loading = false
        })

      this.getDocsInfo()
    }
  },
  computed: {
    filteredDocuments() {
      const documents = this.documents
      return documents.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
    }
  }
})
export default class OrderDocs extends Vue {}
