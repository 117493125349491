
import { Options, Vue } from 'vue-class-component'

import moment from 'moment'

function formatDate(date: any) {
  return moment(new Date(date)).format('DD.MM.YYYY HH:mm')
}

@Options({
  name: 'CompanyCheckList',
  components: {},
  data() {
    return {
      checkLists: []
    }
  },
  porps: {
    readonly: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    formatDate,
    getFullName(worker: any) {
      return `${worker?.username || ''} ${worker?.last_name || ''} ${worker?.first_name || ''} ${worker?.middle_name || ''}`
    }
  },
  created() {
    const id = this.$route.params.id
    if (id) {
      this.$store
        .dispatch('companies/getCheckList', `?company=${id}`)
        .then((res: any) => {
          this.checkLists = res
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }
})
export default class CompanyCheckList extends Vue {}
