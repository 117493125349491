
import { Options, Vue } from 'vue-class-component'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { formatPrice } from '@/utils/helpers'
import PriceItem from '@/components/Prices/PriceItem.vue'
import DefaultPopup from '@/components/Popups/DefaultPopup.vue'

import debounce from '@/utils/debounce'
import moment from 'moment'
import { SERVER_TRANSACTION_ACTION_TYPES, TRANSACTION_ACTION_TYPES_COLORS } from '@/utils/consts'
import { EServerTransactionActionTypes } from '@/types/api-values'
import getErrorMessage from '@/utils/errorMessage'
import Loader from '@/components/Loader/Loader.vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import SuccessModal from '@/components/Modals/SuccessModal.vue'
import { UTable, UDateRange, UCard, UButton, UInput } from 'unit-uikit'

const _today = new Date()
const _yesterday = new Date(new Date().setDate(_today.getDate() - 1))

@Options({
  name: 'Deposit',
  components: {
    UTable,
    MainPageHeader,
    PriceItem,
    DefaultPopup,
    UInput,
    Loader,
    ErrorModal,
    SuccessModal,
    UDateRange,
    UCard,
    UButton
  },
  data() {
    return {
      ready: false,
      transactions: [],
      company: {
        title: ''
      },
      money: {
        free: 0,
        reserved: 0,
        deposit: 0
      },
      formatPrice,
      showPopup: false,
      showPopupWithdraw: false,
      description: '',
      errorFillBalance: '',
      errorDebitBalance: '',
      errorMessage: '',
      amount: 0,
      showSmsPopup: false,
      timer: 60,
      timeout: null,
      sms: '',
      SERVER_TRANSACTION_ACTION_TYPES,
      EServerTransactionActionTypes,
      operationType: null,
      loading: false,
      showErrorPopup: false,
      successCreated: false,
      filterDates: {
        start_date: _yesterday,
        finish_date: _today
      },
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      total_paid: 0,
      total_comission: 0
    }
  },
  methods: {
    formatAmount(amount: any) {
      if (amount) {
        return (amount + '').replaceAll(',', '.').replaceAll(' ', '')
      }
      return amount
    },
    getTransactionColor(type: EServerTransactionActionTypes) {
      if (type) {
        return TRANSACTION_ACTION_TYPES_COLORS[type]
      }
    },
    formatDate(date: any) {
      return moment(new Date(date)).format('DD.MM.YY')
    },
    formatTime(date: any) {
      return moment(new Date(date)).format('HH:mm:ss')
    },
    startTimer() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timer = 60
      this.timeout = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
      }, 1000)
    },
    clearPopupData() {
      this.showPopup = false
      this.description = ''
      this.amount = 0
      this.showPopupWithdraw = false
      this.errorFillBalance = ''
      this.errorDebitBalance = ''
      this.showSmsPopup = false
      this.errorMessage = ''
      this.operationType = null
      this.sms = ''
    },
    fillBalance() {
      this.startTimer()
      this.errorFillBalance = ''
      this.operationType = EServerTransactionActionTypes.INCOME
      const id = this.$route?.params?.id

      if (id && this.description && this.amount) {
        this.$store
          .dispatch('bills/fillBalance', {
            operation_type: this.operationType,
            amount: this.formatAmount(this.amount),
            company_id: id
          })
          .then(() => {
            this.showPopup = false
            this.showSmsPopup = true
          })
          .catch((e: any) => {
            const message = getErrorMessage(e, ['amount', 'operation_type', 'company_id'])
            this.errorFillBalance = message
          })
      }
    },
    debitBalance() {
      this.startTimer()
      this.errorDebitBalance = ''
      this.operationType = EServerTransactionActionTypes.OUTCOME
      const id = this.$route?.params?.id

      if (id && this.description && this.amount) {
        this.$store
          .dispatch('bills/debitBalance', {
            operation_type: this.operationType,
            amount: this.formatAmount(this.amount),
            company_id: id
          })
          .then(() => {
            this.showPopupWithdraw = false
            this.showSmsPopup = true
          })
          .catch((e: any) => {
            const message = getErrorMessage(e, ['amount', 'operation_type', 'company_id'])
            this.errorDebitBalance = message
          })
      }
    },
    checkSms() {
      const id = this.$route?.params?.id
      if (id && this.sms) {
        this.loading = true

        this.$store
          .dispatch('bills/createTransaction', {
            code: this.sms,
            operation_type: this.operationType,
            amount: this.formatAmount(this.amount),
            company_id: id,
            description: this.description
          })
          .then((res: any) => {
            let nonFieldErrors = ''
            const reportStr = res.NEW_REPORT
            if (reportStr) {
              const report = JSON.parse(reportStr)
              Object.keys(report).forEach((key: string) => {
                if (nonFieldErrors) {
                  return
                }
                if (report[key].status + '' === '400' && report[key].data.error) {
                  nonFieldErrors = report[key].data.error
                }
                if (report[key] && report[key].data && report[key].data.non_field_errors) {
                  nonFieldErrors = report[key].data.non_field_errors
                }
              })
            }
            this.loading = false

            if (nonFieldErrors) {
              this.errorMessage = nonFieldErrors
            } else {
              this.clearPopupData()
              this.successCreated = true
            }

            this.updateData()
          })
          .catch((e: any) => {
            this.loading = false

            const message = getErrorMessage(e, ['amount', 'operation_type', 'company_id', 'code'])
            if (message) {
              this.errorMessage = message
            } else {
              this.clearPopupData()
              this.showErrorPopup = true
            }
            this.updateData()
          })
      }
    },
    getSMS() {
      this.startTimer()
      const id = this.$route?.params?.id

      if (id && this.description && this.amount) {
        this.$store
          .dispatch('bills/debitBalance', {
            operation_type: this.operationType,
            amount: this.formatAmount(this.amount),
            company_id: id
          })
          .then()
          .catch((e: any) => {
            const message = getErrorMessage(e, ['amount', 'operation_type', 'company_id'])
            this.errorMessage = message
          })
      }
    },
    updateData() {
      this.getCompanyInfo()
      this.getTransactionsList()
    },
    getCompanyInfo() {
      const id = this.$route?.params?.id

      this.$store
        .dispatch('companies/getCompanyInfo', id)
        .then((response: any) => {
          this.money.deposit = response.company_deposit
          this.money.reserved = response.total_reserve
          this.money.free = response.company_deposit - response.total_reserve
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    getTransactionsList() {
      const id = this.$route?.params?.id

      const date_start = moment(this.filterDates.start_date).format('YYYY-MM-DD')
      const date_end = moment(this.filterDates.finish_date).format('YYYY-MM-DD')

      this.$store
        .dispatch('bills/getCompanyDepositList', {
          id: id,
          queryString: `?page=${this.scrollData.page}&page_size=${this.scrollData.size}` + `&date_start=${date_start}&date_end=${date_end}`
        })
        .then((response: any) => {
          const data = response
          this.scrollData.count = data.count
          this.transactions = data?.results || []
          this.total_paid = data?.total_paid || 0
          this.total_comission = data?.total_comission || 0
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    async getTransactionsListOnScroll() {
      if (this.transactions.length < this.scrollData.count) {
        this.scrollData.page++
        const id = this.$route?.params?.id

        const date_start = moment(this.filterDates.start_date).format('YYYY-MM-DD')
        const date_end = moment(this.filterDates.finish_date).format('YYYY-MM-DD')

        const response = await this.$store.dispatch('bills/getCompanyDepositList', {
          id: id,
          queryString: `?page=${this.scrollData.page}&page_size=${this.scrollData.size}` + `&date_start=${date_start}&date_end=${date_end}`
        })
        const data = response
        if (data.results && data.count) {
          this.scrollData.count = data.count
          this.scrollData.requestCount = data.count
          this.total_paid = data?.total_paid || 0
          this.total_comission = data?.total_comission || 0
          const result = data.results
          this.transactions.push(...result)
        }
      }
    },
    loadTransactionsWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.transactions = []
      this.getTransactionsListOnScroll()
    }
  },
  created() {
    const id = this.$route?.params?.id

    if (id) {
      this.updateData()

      this.$store
        .dispatch('bills/getCompaniesListBills', '')
        .then((response: any) => {
          const company = response.results?.filter((c: any) => {
            return c.company_info?.company_id + '' === id + ''
          })
          const name = company && company[0] && company[0].company_info?.company_name
          if (name) {
            this.company.title = name
          }
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  },
  watch: {
    'filterDates.start_date'() {
      if (this.filterDates.start_date && this.filterDates.finish_date) {
        if (this.debounceloadTransactions) {
          this.debounceloadTransactions()
        } else {
          this.debounceloadTransactions = debounce(() => {
            this.loadTransactionsWithParams()
          })
          this.debounceloadTransactions()
        }
      }
    },
    'filterDates.finish_date'() {
      if (this.filterDates.start_date && this.filterDates.finish_date) {
        if (this.debounceloadTransactions) {
          this.debounceloadTransactions()
        } else {
          this.debounceloadTransactions = debounce(() => {
            this.loadEntitiesWithParams()
          })
          this.debounceloadTransactions()
        }
      }
    }
  }
})
export default class Deposit extends Vue {}
