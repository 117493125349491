
import { Options, Vue } from 'vue-class-component'
import { UInput, UButton, USwitch, UISelect } from 'unit-uikit'
import { formatDate } from '@/utils/helpers'
import { regions } from '@/utils/regions'
import ChangePhoneModal from '@/components/Modals/ChangePhoneModal.vue'
import { jsonToFormData } from '@/utils/jsonToForm'
import { useWorkersStore } from '@/stores/workers'
import COUNTRIES from '@/utils/Countries'
import { SERVER_COUNTRIES } from '@/utils/consts'

function normalizeData(value: any, key: string) {
  switch (key) {
    case 'date_of_issue':
    case 'date_of_birth':
    case 'passport_expires':
      if (value) {
        const [day, month, year] = value.split('.')
        return `${year}-${month}-${day}`
      }
      return ''
    default:
      return value
  }
}

@Options({
  name: 'WorkerInfo',
  components: {
    UInput,
    UButton,
    USwitch,
    ChangePhoneModal,
    UISelect
  },
  data() {
    return {
      workerStore: useWorkersStore(),
      mediaURL: process.env.VUE_APP_URL + '/media/',
      worker: null,
      isCompleteData: true,
      galleryTab: 1,
      isVerified: false,
      verificationStatus: null,
      showModal: false,
      specializationOptions: [],
      citizenshipData: [
        {
          id: SERVER_COUNTRIES.RUSSIA,
          name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
        },
        {
          id: SERVER_COUNTRIES.BELARUS,
          name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
        },
        {
          id: SERVER_COUNTRIES.ARMENIA,
          name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
        },
        {
          id: SERVER_COUNTRIES.KAZAKHSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
        },
        {
          id: SERVER_COUNTRIES.KYRGYZSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
        },
        {
          id: SERVER_COUNTRIES.UKRAINE,
          name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
        },
        {
          id: SERVER_COUNTRIES.DNR,
          name: COUNTRIES[SERVER_COUNTRIES.DNR]
        },
        {
          id: SERVER_COUNTRIES.LNR,
          name: COUNTRIES[SERVER_COUNTRIES.LNR]
        }
      ],
      genderOptions: [
        {
          id: 'M',
          name: 'Мужской'
        },
        {
          id: 'F',
          name: 'Женский'
        }
      ],
      emailError: '',
      last_nameError: '',
      first_nameError: '',
      middle_nameError: '',
      passport_seriesError: '',
      passport_numberError: '',
      passport_whom_givenError: '',
      passport_id_numberError: '',
      date_of_issueError: '',
      passport_expiresError: '',
      date_of_birthError: '',
      indexError: '',
      registered_addressError: '',
      innError: '',
      card_numberError: '',
      place_of_birthError: ''
    }
  },
  methods: {
    getWorkerFieldValue(key: string) {
      switch (key) {
        case 'passport_number':
        case 'passport_id_number':
        case 'passport_series':
        case 'passport_whom_given':
        case 'date_of_issue':
        case 'registered_address':
        case 'passport_expires':
        case 'place_of_birth':
          return this.worker.passport_information[0][key]
        case 'index':
        case 'sex':
        case 'citizenship':
        case 'date_of_birth':
        case 'inn':
        case 'card_number':
          return this.worker.personaldata[key]
        default:
          return this.worker[key]
      }
    },
    updateWorkerInfo(field: string) {
      const id = this.$route?.params?.id

      const data = {
        [field]: normalizeData(this.getWorkerFieldValue(field), field)
      }

      return this.workerStore.updateWorker(id, data)
        .then(() => {
          this[field + 'Error'] = ''
        })
        .catch((data: any) => {
          const errText = data[field]
          this[field + 'Error'] = errText && errText.join(', ')
        })
    },
    selectFile() {
      this.$refs.passportNewDOcRef.click()
    },
    updatePhoto($event: any) {
      this.errors = []
      const file = $event.target.files && $event.target.files[0]
      // const fileSize = file ? file.size / 1024 / 1024 : 0
      // if (fileSize > 32) {
      //   this.errors.push('Размер файла не должен превышать 32МБ')
      // }

      let photo = ''
      switch (this.galleryTab) {
        case 1:
          photo = 'passport_photo_reversal'
          break
        case 2:
          photo = 'passport_photo_registered_address'
          break
        case 3:
          photo = 'selfie'
          break
        default:
          photo = 'file'
      }

      const data = {
        [photo]: file
      }

      const bodyFormData = jsonToFormData(data)

      this.$store
        .dispatch('worker/changeWorkerVerificationStatus', { id: this.$route.params.id, data: bodyFormData })
        .then(() => {
          this.getSingleWorkerData()
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    changePhone() {
      this.showModal = true
    },
    changePhoneFinish() {
      this.getSingleWorkerData()
      this.showModal = false
    },
    formatDate,
    async getSingleWorkerData() {
      const id = this.$route.params.id
      if (id) {
        this.worker = await this.workerStore.fetchWorker(id)
        if (this.isVerified !== this.worker.verified) {
          this.isVerified = this.worker.verified
        }
      }
    },
    changeGalleryTab(tab: number) {
      this.galleryTab = tab
    },
    getPhotoSrc(link: string) {
      if (link.length === 0) return '/img/icons/passport_empty.png'
      return this.mediaURL + link
    },
    getStatusSrc(type: string) {
      const okIcon = '/icons/success.svg'
      const notIcon = '/icons/mistake.svg'

      switch (type) {
        case 'agreement':
          return this.worker.current_agreement ? okIcon : notIcon
        case 'smz':
          return this.worker.personaldata?.is_self_employed ? okIcon : notIcon
        case 'payment':
          return this.worker.payment_details ? okIcon : notIcon
        case 'completedata':
          return this.worker.complete_data ? okIcon : notIcon

        default:
          return notIcon
      }
    },
  },
  computed: {
    regionOptions() {
      return regions?.map((item: any) => {
        return {
          id: item.geoname_name,
          name: item.name
        }
      }) || []
    },
    getPhotoTitle() {
      if (this.galleryTab === 1) {
        return 'Фото разворота паспорта'
      } else if (this.galleryTab === 2) {
        return 'Фото с регистрацией'
      } else {
        return 'Сэлфи'
      }
    },
    mainPhoto() {
      const emptyPhoto = '/img/icons/passport_empty.png'
      let photo = ''
      if (this.worker.passport_information.length) {
        switch (this.galleryTab) {
          case 1:
            photo = this.worker.passport_information[0].passport_photo_reversal
            return photo ? this.mediaURL + photo : emptyPhoto
          case 2:
            photo = this.worker.passport_information[0].passport_photo_registered_address
            return photo ? this.mediaURL + photo : emptyPhoto
          case 3:
            photo = this.worker.passport_information[0].selfie
            return photo ? this.mediaURL + photo : emptyPhoto

          default:
            return emptyPhoto
        }
      } else {
        return emptyPhoto
      }
    },
    getVerificationStatus() {
      return this.isVerified ? 'Верифицирован' : 'Не пройдена'
    }
  },
  created() {
    this.getSingleWorkerData()
    this.workerStore.fetchSpecializations()
      .then((data: {id: string, name: string}) => {
        this.specializationOptions = data
      })
  },
  watch: {
    isVerified(val) {
      this.$store
        .dispatch('worker/changeWorkerVerificationStatus', { id: this.$route.params.id, data: { verified: this.isVerified } })
        .then(() => {
          this.getSingleWorkerData()
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  }
})
export default class WorkerInfo extends Vue {}
