
import { Options, Vue } from 'vue-class-component'
import { ETemplateTypes } from '@/types/api-values'
import { UButton, UInput, UModal, UFileDrop } from 'unit-uikit'
import Loader from '@/components/Loader/Loader.vue'
import VuePdfEmbed from 'vue-pdf-embed'
import { jsonToFormData } from '@/utils/jsonToForm'

@Options({
  components: {
    UButton,
    UInput,
    UModal,
    Loader,
    VuePdfEmbed,
    UFileDrop
  },
  data() {
    const date = new Date()
    const name = `Шаблон_${date.getUTCMonth()}_${date.getUTCFullYear()}`
    const doc = this.doc as any
    return {
      errors: [],
      loading: false,
      file: doc?.file ? { name: doc.name } : null,
      name: doc?.name || name,
      showDocPreview: false,
      hideModal: false,
      docSource: ''
    }
  },
  emits: ['successModal', 'closeModal'],
  props: {
    show: Boolean,
    title: String,
    message: String,
    doc: Object,
    template: ETemplateTypes,
    successBtnLabel: {
      type: String,
      default: 'Добавить'
    }
  },
  methods: {
    dropFile(f: File) {
      this.file = f
      // this.addFile()
    },
    addFile($event: any, flag: boolean) {
      this.errors = []
      const file = flag === true ? $event && $event[0] : $event.target.files && $event.target.files[0]
      const fileSize = file ? file.size / 1024 / 1024 : 0
      if (fileSize > 32) {
        this.errors.push('Размер файла не должен превышать 32МБ')
      } else if (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.errors.push('Возможно загружать только файлы формата DOCX')
      } else {
        this.file = file
      }
    },
    cancelFile() {
      this.file = null
    },

    closeModal() {
      this.cancelFile()
      this.showDocPreview = false
      this.$emit('closeModal')
    },
    uploadTemplatePreview() {
      this.loading = true
      this.showDocPreview = true
      this.hideModal = true

      if (!this.doc) {
        const payload = { file: this.file }

        const formData = jsonToFormData(payload)

        const companyID = this.$route.params.id

        this.$store
          .dispatch('main/previewTemplate', { id: companyID, data: formData })
          .then((res: any) => {
            this.docSource = res.file
          })
          .catch((err: any) => {
            console.error(err)
            this.loading = false
          })
      } else {
        this.showDocPreview = false
        this.loadFileToServer()
      }
    },
    loadFileToServer() {
      const data = {
        id: this.doc?.id,
        name: this.name,
        category: this.template
      } as any

      if (this.file instanceof File) {
        data.file = this.file
      }

      this.$emit('successModal', data)
    },
    getFileUrl(item: any) {
      const URL_CLEAR = process.env.VUE_APP_URL
      const file = item
      if (file) {
        return `${URL_CLEAR}/media/${file.replace('media/', '')}`
      }
      return ''
    }
  },
  computed: {
    templateName() {
      switch (this.template) {
        case ETemplateTypes.agreement:
          return 'договора'
        case ETemplateTypes.task:
          return 'задания'
        case ETemplateTypes.certificate:
          return 'акта'

        default:
          return ''
      }
    }
  }
})
export default class SuccessModal extends Vue {}
