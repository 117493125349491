
import { Options, Vue } from 'vue-class-component'
import { UButton, UInput } from 'unit-uikit'

@Options({
  components: {
    UInput,
    UButton
  },
  data() {
    return {
      loading: false,
      username: '',
      usernameError: '',
      first_name: '',
      last_name: '',
      middle_name: ''
    }
  },
  props: {
    show: Boolean,
    title: String,
    message: String,
    successBtnLabel: {
      type: String,
      default: 'Отправить'
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    addNewUser() {
      this.loading = true

      this.usernameError = ''
      const username = this.username.replace(/-/g, '')

      this.$store
        .dispatch('companies/addCompanyCustomer', {
          username: username,
          first_name: this.first_name,
          last_name: this.last_name,
          middle_name: this.middle_name
        })
        .then(() => {
          this.loading = false
          this.$emit('successModal')
        })
        .catch((e: any) => {
          const data = e.response.data
          const errText = data.username && data.username.join(', ')
          this.loading = false

          if (errText) {
            this.usernameError = errText
          } else {
            this.$emit('successModal', e)
          }
        })
    }
  },
  computed: {
    addBtnDisabled() {
      return !(this.last_name && this.first_name && this.middle_name && this.username.length === 16)
    }
  }
})
export default class SuccessModal extends Vue {}
