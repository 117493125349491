
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'

@Options({
  name: 'DefaultPopup',
  components: {
    UButton
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    cancelBtn: {
      type: String,
      default: 'Отмена'
    },
    okBtn: {
      type: String,
      default: 'Ок'
    }
  },
  data() {
    return {}
  },
  methods: {
    closePopup() {
      this.$emit('close')
    }
  }
})
export default class DefaultPopup extends Vue {}
