
import { Options, Vue } from 'vue-class-component'
import { formatDate } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import debounce from '@/utils/debounce'
import { UAlert, UInput, UTable } from 'unit-uikit'

@Options({
  name: 'NotVerifiedCompanies',
  components: {
    Loader,
    UAlert,
    UInput,
    UTable
  },
  data() {
    return {
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      loading: true,
      entities: [],
      searchString: ''
    }
  },
  methods: {
    formatDate,
    getFullName(worker: any) {
      return `${worker?.last_name || ''} ${worker?.first_name || ''} ${worker?.middle_name || ''}`
    },
    loadEntitiesWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.entities = []
      this.loadEntities()
    },
    async loadEntities() {
      if (this.entities.length < this.scrollData.count) {
        this.scrollData.page++

        let queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&verified=False`

        if (this.searchString) {
          queryString += `&name=${this.searchString}`
        }

        const response = await this.$store.dispatch('companies/getEntitiesList', queryString)

        const data = response
        if (data.results && data.count) {
          this.scrollData.count = data.count
          const entities = data.results
          this.entities.push(...entities)
        }
      }
    },
    loadContent() {
      this.loading = true

      this.$store
        .dispatch('companies/getEntitiesList', `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&verified=False`)
        .then((res: any) => {
          this.scrollData.count = res.count
          this.entities = res.results
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    },
    moveToEntity(item: any) {
      const id = item.director?.company
      if (id) {
        this.$router.push({
          name: 'companies.info',
          params: {
            id
          }
        })
      } else {
        alert('Извините, отсутствует ссылка на компанию. Обратитесь к администратору')
      }
    }
  },
  created() {
    this.loadContent()
  },
  watch: {
    searchString() {
      if (this.debounceloadEntities) {
        this.debounceloadEntities()
      } else {
        this.debounceloadEntities = debounce(() => {
          this.loadEntitiesWithParams()
        })
        this.debounceloadEntities()
      }
    }
  }
})
export default class NotVerifiedCompanies extends Vue {}
