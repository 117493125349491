
import { Options, Vue } from 'vue-class-component'
import { UInput, UISelect, USwitch } from 'unit-uikit'

import { EEntityServerValues } from '@/types/api-values'
import { jsonToFormData } from '@/utils/jsonToForm'

function normalizeRepresentativesRecords(item: any) {
  return {
    id: item.id,
    name: `${item?.last_name || ''} ${item?.first_name || ''} ${item?.middle_name || ''} ${item.position === 'director' ? '(гендир)' : ''}`
  }
}

@Options({
  name: 'CompanyMainInfo',
  components: {
    UInput,
    USwitch,
    UISelect
  },
  data() {
    const info = this.info as any
    return {
      checkList: {},
      EEntityServerValues,
      type: info?.type,
      name: info?.name,
      nameError: '',
      ogrn: info?.ogrn,
      ogrnError: '',
      inn: info?.inn,
      innError: '',
      signer: info?.position,
      signerError: '',
      email: info?.email,
      emailError: '',
      phone: info?.phone,
      phoneError: '',
      okved: info?.okved,
      okvedError: '',
      kpp: info?.kpp,
      kppError: '',
      actual_address: info?.actual_address,
      actual_addressError: '',
      registered_address: info?.registered_address,
      registered_addressError: '',
      full_company_name: info?.full_company_name,
      full_company_nameError: '',
      isVerified: !!info?.verified,
      postionOptions: []
    }
  },
  props: {
    info: { type: Object },
    readonly: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    uploadCheckList(event: any) {
      const file = event?.target?.files[0]
      const companyId = this.$route?.params?.id

      const data = {
        company: companyId,
        name: file.name,
        file
      }

      const bodyFormData = jsonToFormData(data)

      return this.$store
        .dispatch('companies/uploadEntityCheckList', bodyFormData)
        .then((res: any) => {
          this.checkList = res
        })
        .catch((err: any) => {
          console.log(err)
        })
    },
    updateCompanyInfo(field: string) {
      if (this.readonly) {
        return
      }

      this[field + 'Error'] = ''
      const id = this.$route?.params?.id
      const data = {
        [field]: this[field]
      }
      if (field === 'phone') {
        data.phone = data.phone.replace(/-/g, '')
      }
      if (field === 'signer') {
        return this.$store
          .dispatch('companies/updateEntitySigner', { id: id, current_signer: this.signer })
          .then(() => {
            this[field + 'Error'] = ''
          })
          .catch((err: any) => {
            console.log(err)
            const data = err.response.data
            const errText = data[field]
            this[field + 'Error'] = errText && errText.join(', ')
          })
      }

      return this.$store
        .dispatch('companies/updateEntityRecord', { id: id, data: data })
        .then(() => {
          this[field + 'Error'] = ''
        })
        .catch((err: any) => {
          console.log(err)
          const data = err.response.data
          const errText = data[field]
          this[field + 'Error'] = errText && errText.join(', ')
        })
    },
    verifyCompany(verified: boolean) {
      this.isVerified = verified

      const id = this.$route?.params?.id

      return this.$store.dispatch('companies/updateEntityRecord', { id: id, data: { verified: verified } }).catch((e: any) => {
        console.error(e)
      })
    }
  },
  computed: {
    getVerificationStatus() {
      return this.isVerified ? 'Верифицирован' : 'Не пройдена'
    }
  },
  created() {
    const id = this.$route.params.id
    if (id) {
      this.$store
        .dispatch('companies/getCheckList', `?company=${id}`)
        .then((res: any) => {
          this.checkList = res[0] || null
        })
        .catch((e: any) => {
          console.error(e)
        })

      this.$store
        .dispatch('companies/getCompanyProcurationRepresentatives', id)
        .then((res: any) => {
          if (res && res.length) {
            this.postionOptions = res.map(normalizeRepresentativesRecords)
          }
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  }
})
export default class CompanyMainInfo extends Vue {}
